import { AxiosInstance } from 'axios';
import { AxiosParam, create, send } from 'summer';
import { AbrirTicketParam } from '../models/dtos/ger/abrirTicketParam';
import { Administradora } from '../models/dtos/ger/administradora';
import { Agenda } from '../models/dtos/ger/agenda';
import { AlteracaoSenha } from '../models/dtos/ger/alteracaoSenha';
import { Armazenamento } from '../models/dtos/ger/armazenamento';
import { AutomatizacaoConfigEmpresa } from '../models/dtos/ger/automatizacaoConfigEmpresa';
import { AvaliacaoTicketBitrix } from '../models/dtos/ger/avaliacaoTicketBitrix';
import { CepBairro } from '../models/dtos/ger/cepBairro';
import { CepLocalidade } from '../models/dtos/ger/cepLocalidade';
import { CepLogradouro } from '../models/dtos/ger/cepLogradouro';
import { CepUF } from '../models/dtos/ger/cepUF';
import { CertificadoDigital } from '../models/dtos/ger/certificadoDigital';
import { CertificadoDigitalParam } from '../models/dtos/ger/certificadoDigitalParam';
import { CertificadoVencidoParam } from '../models/dtos/ger/certificadoVencidoParam';
import { CheckSessaoValidaParam } from '../models/dtos/ger/checkSessaoValidaParam';
import { ClienteInfoExample } from '../models/dtos/ger/clienteInfoExample';
import { ComentarioTicketBitrix } from '../models/dtos/ger/comentarioTicketBitrix';
import { ConhecimentoTransporte } from '../models/dtos/ger/conhecimentoTransporte';
import { ConhecimentoTransporteConta } from '../models/dtos/ger/conhecimentoTransporteConta';
import { ConhecimentoTransporteEntrada } from '../models/dtos/ger/conhecimentoTransporteEntrada';
import { ConhecimentoTransporteSaida } from '../models/dtos/ger/conhecimentoTransporteSaida';
import { CreditoSms } from '../models/dtos/ger/creditoSms';
import { DadosEmpresaGlobalParameter } from '../models/dtos/ger/dadosEmpresaGlobalParameter';
import { DadosMensagem } from '../models/dtos/ger/dadosMensagem';
import { Empresa } from '../models/dtos/ger/empresa';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';
import { GrupoEmpresa } from '../models/dtos/ger/grupoEmpresa';
import { GrupoRelatorio } from '../models/dtos/ger/grupoRelatorio';
import { GrupoRelatorioItem } from '../models/dtos/ger/grupoRelatorioItem';
import { JobInfo } from '../models/dtos/ger/jobInfo';
import { LancamentoConnect } from '../models/dtos/ger/lancamentoConnect';
import { Mensageria } from '../models/dtos/ger/mensageria';
import { Notificacao } from '../models/dtos/ger/notificacao';
import { NovoComentarioTicketParam } from '../models/dtos/ger/novoComentarioTicketParam';
import { RequestSolicitacao } from '../models/dtos/ger/requestSolicitacao';
import { Responsavel } from '../models/dtos/ger/responsavel';
import { RespostaPesquisaNPS } from '../models/dtos/ger/respostaPesquisaNPS';
import { Satisfacao } from '../models/dtos/ger/satisfacao';
import { Solicitacao } from '../models/dtos/ger/solicitacao';
import { SolicitacaoPage } from '../models/dtos/ger/solicitacaoPage';
import { TicketBitrix } from '../models/dtos/ger/ticketBitrix';
import { TipoSolicitacao } from '../models/dtos/ger/tipoSolicitacao';
import { TributacaoEmpresa } from '../models/dtos/ger/tributacaoEmpresa';
import { Usuario } from '../models/dtos/ger/usuario';
import { UsuarioAlteracaoLoteParam } from '../models/dtos/ger/usuarioAlteracaoLoteParam';
import { UsuarioEmpresaAtual } from '../models/dtos/ger/usuarioEmpresaAtual';
import { UsuarioExample } from '../models/dtos/ger/usuarioExample';
import { UsuarioHistAcesso } from '../models/dtos/ger/usuarioHistAcesso';
import { UsuarioVW } from '../models/dtos/ger/usuarioVW';
import { VisualizouTour } from '../models/dtos/ger/visualizouTour';
import { CertificadoDigitalRuleMessage } from '../models/dtos/mfp/certificadoDigitalRuleMessage';
import { Evento } from '../models/dtos/mfp/evento';
import { AliquotaUF } from '../models/dtos/mlf/aliquotaUF';
import { LOCAL_STORAGE_SOCKET_HASH } from '../utilities/constantes';
import { getUniqueUserHash } from '../utilities/login.util';
import { Retrospectiva } from '../models/dtos/ger/retrospectiva';

export function getHeaders(extraHeaders?: any) {
  return {
    headers: {
      ...(extraHeaders || {}),
      socketHash: sessionStorage.getItem(LOCAL_STORAGE_SOCKET_HASH),
      uniqueUserHash: getUniqueUserHash(),
    },
  };
}

function getService(addGzip = true): AxiosInstance {
  return create('/ger', addGzip);
}

export function getArquivoDigitalService(): AxiosInstance {
  return create('/arquivoDigital', false);
}

export function findAllJobs(param: AxiosParam<JobInfo[]>) {
  send(getService().get<JobInfo[]>('/findAllJobs'), param);
}

export function removeFinishedJobs(param: AxiosParam<any>) {
  send(getService().get('/removeFinishedJobs'), param);
}

export function obterGlobalParameter(tipoLogin: string, loginUsuario: Usuario, param: AxiosParam<GlobalParameter>) {
  send(getService().post<GlobalParameter>('/obterGlobalParameter', loginUsuario, getHeaders({ acesso: tipoLogin })), param);
}

export function atualizarGlobalParameter(usuario: Usuario, param: AxiosParam<GlobalParameter>) {
  send(getService().post<GlobalParameter>('/atualizarGlobalParameter', usuario), param);
}

export function marcarNotificacaoVista(notificacao: Notificacao, param: AxiosParam<void>) {
  send(getService().post<void>('/marcarNotificacaoVista', notificacao), param);
}

export function validarCPFResponsavel(responsavel: Responsavel, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validarCPFResponsavel', responsavel), param);
}

export function salvarUsuarioEmpresaAtual(usuarioEmpresaAtual: UsuarioEmpresaAtual, param?: AxiosParam<DadosEmpresaGlobalParameter>) {
  send(getService().post<DadosEmpresaGlobalParameter>('/salvarUsuarioEmpresaAtual', usuarioEmpresaAtual), param);
}

export function verificarMovimentoPlanoConta(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarMovimentoPlanoConta', empresaRaiz), param);
}

export function verificarMovimentoHistorico(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarMovimentoHistorico', empresaRaiz), param);
}

export function verificarMovimentoCentroCusto(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarMovimentoCentroCusto', empresaRaiz), param);
}

export function verificarMovimentoProduto(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarMovimentoProduto', empresaRaiz), param);
}

export function verificarMovimentoServico(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarMovimentoServico', empresaRaiz), param);
}

export function verificarMovimentoForcli(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarMovimentoForcli', empresaRaiz), param);
}

export function verificarTipoMovimentacaoInterna(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarTipoMovimentacaoInterna', empresaRaiz), param);
}

export function verificarGrupoEvento(empresaRaiz: GrupoEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/verificarGrupoEvento', empresaRaiz), param);
}

export function existeEmpresaPorCNPJ(cnpj: string, param?: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/existeEmpresaPorCNPJ/${cnpj}`), param);
}

export function importarEmpresaPorCNPJRF(cnpj: string, param?: AxiosParam<Empresa>) {
  send(getService().get<Empresa>(`/importarEmpresaPorCNPJRF/${cnpj}`), param);
}

export function existeEmpresaCadastroInvalido(userId: number, param?: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/existeEmpresaCadastroInvalido/${userId}`), param);
}

export function validarCNPJEmpresa(empresa: Empresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validarCNPJEmpresa', empresa), param);
}

export function validarCPFEmpresa(empresa: Empresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validarCPFEmpresa', empresa), param);
}

export function empresaFilialSemGrupoPlanoContaIgual(idGerempresa: number, idGrupoPlanoConta: number, param?: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/empresaFilialSemGrupoPlanoContaIgual/${idGerempresa}/${idGrupoPlanoConta}`), param);
}

export function validarSelecaoMatrizFilial(idEmpresaFilial: number, idEmpresaMatriz: number, param?: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/validarSelecaoMatrizFilial/${idEmpresaFilial}/${idEmpresaMatriz}`), param);
}

export function configurarEmpresa(automatizacao: AutomatizacaoConfigEmpresa, param?: AxiosParam<boolean>) {
  send(getService().post<boolean>('/configurarEmpresa', automatizacao), param);
}

export function findConfigCertificadoDigital(idEmpresa: number, param?: AxiosParam<CertificadoDigital>) {
  send(getService().get<CertificadoDigital>(`/findConfigCertificadoDigital/${idEmpresa}`), param);
}

export function validarCertificadoDigital(certificadoDigital: CertificadoDigital, param: AxiosParam<CertificadoDigitalParam>) {
  const formDataSend = new FormData();
  formDataSend.append('senhaCertificadoDigital', certificadoDigital.senhaCertificadoDigital);

  if (certificadoDigital.arquivos) {
    for (const arquivo of certificadoDigital.arquivos) {
      formDataSend.append('arquivos', arquivo.file, arquivo.nomeArquivo);
    }
    send(getService().post<CertificadoDigitalParam>('/validarCertificadoDigital', formDataSend), param);
  } else {
    send(getService().post<CertificadoDigitalParam>('/validarCertificadoDigitalFromDB', certificadoDigital), param);
  }
}

export function findEventosConnect(idEmpresa: number, param?: AxiosParam<Evento[]>) {
  send(getService().get<Evento[]>(`/findEventosConnect/${idEmpresa}`), param);
}

export function sincronizarUsuariosWS(param?: AxiosParam<void>) {
  send(getService().post<void>('/sincronizarUsuariosWS'), param);
}

export function removerUsuarios(usuarios: UsuarioVW[], param?: AxiosParam<void>) {
  send(getService().post<void>('/removerUsuarios', usuarios), param);
}

export function persistUsuario(usuario: Usuario, param?: AxiosParam<any>) {
  send(getService().post('/persistUsuario', usuario), param);
}

export function obterVersaoCalima(param?: AxiosParam<string>) {
  send(getService().get<string>('/obterVersaoCalima'), param);
}

export function loadFotoAdministradora(administradora: Administradora, param: AxiosParam<string>) {
  send(getService().post<string>('/loadFotoAdministradora', administradora), param);
}

export function validarDuplicidadeBairro(cepBairro: CepBairro, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validarDuplicidadeBairro', cepBairro), param);
}

export function findAndValidateCertificadoDigital(idEmpresa: number, param?: AxiosParam<CertificadoDigitalRuleMessage>) {
  send(getService().get<CertificadoDigitalRuleMessage>(`/findAndValidateCertificadoDigital/${idEmpresa}`), param);
}

export function gerarArquivoDigital<T>(usuario: Usuario, exportacaoParam: T, callExportName: string, param?: AxiosParam<string>) {
  const config = {
    headers: {
      socketHash: sessionStorage.getItem(LOCAL_STORAGE_SOCKET_HASH),
    },
  };
  send(getArquivoDigitalService().post<string>(`/${callExportName}`, { usuario, exportacaoParam }, config), param);
}

export function buscarLogradouroPorCep(param: AxiosParam<CepLogradouro[]>, cep: string) {
  send(getService().get<CepLogradouro[]>(`/buscarLogradouroPorCep/${cep}`), param);
}

export function getUrlAcessoChat(usuario: string, codigoCliente: string, setor: string, param: AxiosParam<any>) {
  send(getService().get(`/getUrlAcessoChat/${usuario}/${codigoCliente}/${setor}`), param);
}

export function notificarSatisfacao(satisfacao: Satisfacao, param: AxiosParam<void>) {
  send(getService().post<void>('/notificarSatisfacao', satisfacao), param);
}

export function findUFEmpresa(idGerEmpresa: number, param: AxiosParam<CepUF>) {
  send(getService().get<CepUF>(`/findUFEmpresa/${idGerEmpresa}`), param);
}

export function isInsereDataEmissao(idGerEmpresa: number, param: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/isInsereDataEmissao/${idGerEmpresa}`), param);
}

export function getIcmsApurado(idEmpresa: number, param: AxiosParam<string[]>) {
  send(getService().get<string[]>(`/getIcmsApurado/${idEmpresa}`), param);
}

export function findUFForcli(idGerForcli: number, param: AxiosParam<CepUF>) {
  send(getService().get<CepUF>(`/findUFForcli/${idGerForcli}`), param);
}

export function findLocalidadeForcli(idGerForcli: number, param: AxiosParam<CepLocalidade>) {
  send(getService().get<CepUF>(`/findLocalidadeForcli/${idGerForcli}`), param);
}

export function getAliquotaUF(idUFOrigem: number, idUFDestino: number, param: AxiosParam<AliquotaUF>) {
  send(getService().get<AliquotaUF>(`/getAliquotaUF/${idUFOrigem}/${idUFDestino}`), param);
}

export function gerarContabilizacaoAutomaticaEntrada(
  conhecimentoTransporte: ConhecimentoTransporteEntrada,
  empresa: Empresa,
  param: AxiosParam<ConhecimentoTransporteConta[]>
) {
  send(
    getService().post<ConhecimentoTransporteConta[]>('/gerarContabilizacaoAutomaticaEntrada', {
      conhecimentoTransporte,
      empresa,
    }),
    param
  );
}

export function gerarContabilizacaoAutomaticaSaida(
  conhecimentoTransporte: ConhecimentoTransporteSaida,
  empresa: Empresa,
  param: AxiosParam<ConhecimentoTransporteConta[]>
) {
  send(
    getService().post<ConhecimentoTransporteConta[]>('/gerarContabilizacaoAutomaticaSaida', { conhecimentoTransporte, empresa }),
    param
  );
}

export function alterarSenha(alteracaoSenha: AlteracaoSenha, param: AxiosParam<void>) {
  send(getService().post<void>('/alterarSenha', alteracaoSenha), param);
}

export function buscarRespondidas(solicitacao: SolicitacaoPage, param: AxiosParam<any>) {
  send(getService().post<any>('/buscarRespondidas', solicitacao), param);
}

export function buscarSolicitacoesFinalizadas(solicitacao: SolicitacaoPage, param: AxiosParam<any>) {
  send(getService().post<any>('/buscarSolicitacoesFinalizadas', solicitacao), param);
}

export function buscarPendentes(solicitacao: SolicitacaoPage, param: AxiosParam<any>) {
  send(getService().post<any>('/buscarPendentes', solicitacao), param);
}

export function buscarTiposSolicitacao(param: AxiosParam<TipoSolicitacao[]>) {
  send(getService().get<TipoSolicitacao[]>('/buscarTiposSolicitacao'), param);
}

export function enviarSolicitacao(enviar: RequestSolicitacao, param: AxiosParam<Solicitacao>) {
  const formDataSend = new FormData();

  if (enviar.id) {
    formDataSend.append('id', enviar.id.toString());
  }

  if (enviar.idUsuarioLogado) {
    formDataSend.append('idUsuarioLogado', enviar.idUsuarioLogado);
  }

  if (enviar.status) {
    formDataSend.append('status', enviar.status);
  }

  if (enviar.codigotipoSolicitacao) {
    formDataSend.append('tipoSolicitacaoDTO.codigo', enviar.codigotipoSolicitacao);
  }

  if (enviar.mensagem) {
    formDataSend.append('mensagem', enviar.mensagem);
  }
  if (enviar.destinatario) {
    formDataSend.append('destinatario', enviar.destinatario);
  }

  if (enviar.idEmpresa) {
    formDataSend.append('idEmpresa', enviar.idEmpresa);
  }

  if (enviar.ultimaAlteracaoUsuarioLogado) {
    formDataSend.append('ultimaAlteracaoUsuarioLogado', String(enviar.ultimaAlteracaoUsuarioLogado));
  }

  if (enviar.arquivos) {
    for (const arquivo of enviar.arquivos) {
      formDataSend.append('arquivos', arquivo.file, arquivo.nomeArquivo);
    }
  }

  if (enviar.emailReplyTo) {
    formDataSend.append('emailReplyTo', enviar.emailReplyTo);
  }

  if (enviar.dadosAssinaturaEmail) {
    if (enviar.dadosAssinaturaEmail.nomeAssinatura) {
      formDataSend.append('dadosAssinaturaEmail.nomeAssinatura', enviar.dadosAssinaturaEmail.nomeAssinatura);
    }
    if (enviar.dadosAssinaturaEmail.razaoSocialAdm) {
      formDataSend.append('dadosAssinaturaEmail.razaoSocialAdm', enviar.dadosAssinaturaEmail.razaoSocialAdm);
    }
    if (enviar.dadosAssinaturaEmail.enderecoAdmFmt) {
      formDataSend.append('dadosAssinaturaEmail.enderecoAdmFmt', enviar.dadosAssinaturaEmail.enderecoAdmFmt);
    }
    if (enviar.dadosAssinaturaEmail.telefoneAdm) {
      formDataSend.append('dadosAssinaturaEmail.telefoneAdm', enviar.dadosAssinaturaEmail.telefoneAdm);
    }
  }

  send(getService(false).post<Solicitacao>('/enviarSolicitacao', formDataSend), param);
}

export function finalizarSolicitacao(requestSolicitacao: RequestSolicitacao, param: AxiosParam<Solicitacao>) {
  send(getService().post<Solicitacao>('/finalizarSolicitacao', requestSolicitacao), param);
}

export function deleteSolicitacao(idSolicitacao: string, param: AxiosParam<void>) {
  send(getService().delete<void>(`/deleteSolicitacao/${idSolicitacao}`), param);
}

export function efetuarDownloadArquivo(enviar: DadosMensagem, param: AxiosParam<string>) {
  send(getService().post<string>('/efetuarDownloadArquivo', enviar), param);
}

export function buscarUsoArmazenamento(param?: AxiosParam<Armazenamento>) {
  send(getService().get<Armazenamento>('/buscarUsoArmazenamento'), param);
}

export function buscarUsoCreditoSms(param?: AxiosParam<CreditoSms>) {
  send(getService().get<CreditoSms>('/buscarUsoCreditoSms'), param);
}

export function atualizarAtividadeSistema(param?: AxiosParam<void>) {
  send(getService().post<void>('/atualizarAtividadeSistema'), param);
}

export function countConhecimentoDuplicado(conhecimentoTransporte: ConhecimentoTransporte, param: AxiosParam<number>) {
  send(getService().post<number>('/countConhecimentoDuplicado', conhecimentoTransporte), param);
}

export function getDetalhesLancamento(solicitacao: Solicitacao, param: AxiosParam<LancamentoConnect>) {
  send(getService().post<LancamentoConnect>('/getDetalhesLancamento', solicitacao), param);
}

export function importarLancamentoConnect(solicitacaoDTO: Solicitacao, lancamentoConnectDTO: LancamentoConnect, param: AxiosParam<boolean>) {
  const formData = new FormData();
  if (solicitacaoDTO) {
    formData.append('solicitacaoDTO', JSON.stringify(solicitacaoDTO));
  }
  if (lancamentoConnectDTO) {
    formData.append('lancamentoConnectDTO', JSON.stringify(lancamentoConnectDTO));
  } else {
    formData.append('lancamentoConnectDTO', null);
  }

  send(getService().post<boolean>('/importarLancamentoConnect', formData), param);
}

export function agendarRotina(agenda: Agenda, param: AxiosParam<void>) {
  send(getService().post<void>('/agendarRotina', agenda), param);
}

export function excluirRotina(idAgenda: number, param: AxiosParam<void>) {
  send(getService().delete<void>(`/excluirRotina/${idAgenda}`), param);
}

export function findAdministradora(param: AxiosParam<Administradora>) {
  send(getService().get<Administradora>('/findAdministradora'), param);
}

export function notificarVisualizacaoMensagemController(mensageria: Mensageria, param: AxiosParam<void>) {
  send(getService().post<void>('/notificarVisualizacaoMensagemController', mensageria), param);
}

export function cancelarRecebimentoMensagemController(mensageria: Mensageria, param: AxiosParam<void>) {
  send(getService().post<void>('/cancelarRecebimentoMensagemController', mensageria), param);
}

export function obterInfo(param: AxiosParam<any>) {
  send(getService().get<any>('/obterInfo'), param);
}

export function registrarAcessoRecente(idUsuario: number, modulo: string, id: string, param: AxiosParam<UsuarioHistAcesso[]>) {
  send(getService().post<UsuarioHistAcesso[]>(`/registrarAcessoRecente/${idUsuario}/${modulo}/${id}`), param);
}

export function atualizarAcessoFavorito(idUsuario: number, modulo: string, id: string, param: AxiosParam<boolean>) {
  send(getService().post<boolean>(`/atualizarAcessoFavorito/${idUsuario}/${modulo}/${id}`), param);
}

export function removerFavorito(idUsuario: number, urls: string[], param: AxiosParam<UsuarioHistAcesso[]>) {
  send(getService().post<UsuarioHistAcesso[]>(`/removerFavorito/${idUsuario}`, urls), param);
}

export function buscarHistoricoAcesso(idUsuario: number, param: AxiosParam<UsuarioHistAcesso[]>) {
  send(getService().get<UsuarioHistAcesso[]>(`/buscarHistoricoAcesso/${idUsuario}`), param);
}

export function findFirstClienteConnectEmpresa(idGerEmpresa: number, param: AxiosParam<Usuario>) {
  send(getService().get<Usuario>(`/findFirstClienteConnectEmpresa/${idGerEmpresa}`), param);
}

export function getIdsUsuariosEmpresa(idGerEmpresa: number, param: AxiosParam<number[]>) {
  send(getService().get<number[]>(`/getIdsUsuariosEmpresa/${idGerEmpresa}`), param);
}

export function findFiliais(ids: number[], param: AxiosParam<number[]>) {
  send(getService().post<number[]>('/findFiliais', ids), param);
}

export function salvarDadosRelatorio(idUsuario: number, modulo: string, relatorio: string, dados: any, param: AxiosParam<void>) {
  send(getService().post<void>(`/salvarDadosRelatorio/${idUsuario}/${modulo}/${relatorio}`, dados), param);
}

export function getDadosRelatorio(idUsuario: number, modulo: string, relatorio: string, param: AxiosParam<any>) {
  send(getService().get<any>(`/getDadosRelatorio/${idUsuario}/${modulo}/${relatorio}`), param);
}

export function addRelatorioNoGrupo(idGrupo: number, relatorio: GrupoRelatorioItem, param: AxiosParam<void>) {
  send(getService().post<void>(`/addRelatorioNoGrupo/${idGrupo}`, relatorio), param);
}

export function getGruposRelPorModulo(modulo: string, param: AxiosParam<GrupoRelatorio[]>) {
  send(getService().get<GrupoRelatorio[]>(`/getGruposRelPorModulo/${modulo}`), param);
}

export function canRemoveEmpresa(param: AxiosParam<boolean>) {
  send(getService().get<boolean>('/canRemoveEmpresa'), param);
}

export function removerEmpresa(idEmpresa: number, param: AxiosParam<void>) {
  send(getService().delete<void>(`/removerEmpresa/${idEmpresa}`), param);
}

export function findNewEmpresaAndUpdateEmpresaAtual(idUsuario: number, param?: AxiosParam<Empresa>) {
  send(getService().get<Empresa>(`/findNewEmpresaAndUpdateEmpresaAtual/${idUsuario}`), param);
}

export function enviarEmpresaConnect(empresa: Empresa, param: AxiosParam<void>) {
  send(getService().post<void>('/enviarEmpresaConnect', empresa), param);
}

export function getCabecalhoImportacao(idArquivos: string, numeroColunasObrigatorias: number, param: AxiosParam<string[]>) {
  send(getService().post<string[]>(`/getCabecalhoImportacao/${numeroColunasObrigatorias}/${idArquivos}`), param);
}

export function findUsuariosConnect(param?: AxiosParam<Usuario[]>) {
  send(getService().post<Usuario[]>('/findUsuariosConnect'), param);
}

export function limparNotificacoes(idUsuario: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/limparNotificacoes/${idUsuario}`), param);
}

export function checkSessaoValida(checkSessaoParam: CheckSessaoValidaParam, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/checkSessaoValida', checkSessaoParam), param);
}

export function salvarCPF(usuario: UsuarioExample, param: AxiosParam<Usuario>) {
  send(getService().post<Usuario>('/salvarCPF', usuario), param);
}

export function exibirRetrospectiva(idUsuario: number, param: AxiosParam<Retrospectiva>) {
  send(getService().get<Retrospectiva>(`/exibirRetrospectiva/${idUsuario}`), param);
}

export function marcarVisualizouRetrospectiva(idUsuario: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/marcarVisualizouRetrospectiva/${idUsuario}`), param);
}

export function obterInfoRetrospectiva(param: AxiosParam<ClienteInfoExample>) {
  send(getService().get<ClienteInfoExample>('/obterInfoRetrospectiva'), param);
}

export function checkVisualizouTour(itemTour: string, param: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/checkVisualizouTour/${itemTour}`), param);
}

export function checkUsuarioVisualizouTour(idUsuario: number, itemTour: string, param: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/checkUsuarioVisualizouTour/${idUsuario}/${itemTour}`), param);
}

export function salvarUsuarioVisualizouTour(idUsuario: number, itemTour: string, param: AxiosParam<void>) {
  send(getService().post<void>(`/salvarUsuarioVisualizouTour/${idUsuario}/${itemTour}`), param);
}

export function getUltimaVisualizacaoTour(idUsuario: number, itemTour: string, param: AxiosParam<VisualizouTour>) {
  send(getService().get<VisualizouTour>(`/getUltimaVisualizacaoTour/${idUsuario}/${itemTour}`), param);
}

export function alterarUsuarioEmLote(usuarioParam: UsuarioAlteracaoLoteParam, param: AxiosParam<void>) {
  send(getService().post<void>('/alterarUsuarioEmLote', usuarioParam), param);
}

export function getEmpresaPertenceGO(idEmpresa: number, param: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/getEmpresaPertenceGO/${idEmpresa}`), param);
}

export function findTributacaoNoPeriodo(referencia: string, idEmpresa: number, param: AxiosParam<TributacaoEmpresa>) {
  send(getService().post<TributacaoEmpresa>(`/findTributacaoNoPeriodo/${referencia}/${idEmpresa}`), param);
}

export function removerRecenteFavorito(telasARemover: string[], idUsuario: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/removerRecenteFavorito/${idUsuario}`, telasARemover), param);
}

export function totalUsuariosAtivos(param: AxiosParam<any>) {
  send(getService().get<any>('/totalUsuariosAtivos'), param);
}

export function trocarTema(idUsuario: number, param: AxiosParam<Usuario>) {
  send(getService().post<Usuario>(`/trocarTema/${idUsuario}`), param);
}

export function deveExibirPesquisaNPS(usuario: string, param: AxiosParam<any>) {
  send(getService().get<any>(`/deveExibirPesquisaNPS?usuario=${usuario}`), param);
}

export function salvarPesquisaNPS(resposta: RespostaPesquisaNPS, param: AxiosParam<void>) {
  send(getService().post<void>('/salvarPesquisaNPS', resposta), param);
}

export function verificarEmpCertifVencido(idUsuario: number, param: AxiosParam<CertificadoVencidoParam[]>) {
  send(getService().post<CertificadoVencidoParam[]>(`/verificarEmpCertifVencido/${idUsuario}`), param);
}

export function existeCertificadoVencido(idUsuario: number, param: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/existeCertificadoVencido/${idUsuario}`), param);
}

export function cancelJobs(jobIds: number[], param: AxiosParam<void>) {
  send(getService().post(`/cancelJobs`, jobIds), param);
}

export function validarAdministradorMaster(senha: string, param: AxiosParam<boolean>) {
  const formData = new FormData();
  formData.append('senha', senha);
  send(getService().post(`/validarAdministradorMaster`, formData), param);
}

export function atualizarLogoEmpresa(idEmpresa: number, file: File | string, param: AxiosParam<void>) {
  const body = new FormData();
  body.append('file', file);
  send(getService().post<void>(`/atualizarLogoEmpresa/${idEmpresa}`, body), param);
}

export function getLogoEmpresa(logoEmpresa: string, param: AxiosParam<any>) {
  send(getService().get<any>(`/getLogoEmpresa/${logoEmpresa}`), param);
}

export function logDownloadCertificado(empresaId: number, param: AxiosParam<void>) {
  send(getService().post(`/logDownloadCertificado/${empresaId}`), param);
}

export function findCompleteEmpresaById(idEmpresa: number, param?: AxiosParam<CertificadoDigital>) {
  send(getService().get<Empresa>(`/findCompleteEmpresaById/${idEmpresa}`), param);
}

export function consultarTickets(exibirConcluidos: boolean, param: AxiosParam<TicketBitrix[]>) {
  send(getService().get<TicketBitrix[]>(`/consultarTickets/${exibirConcluidos}`), param);
}

export function abrirTicket(abrirTicketParam: AbrirTicketParam, param: AxiosParam<TicketBitrix>) {
  send(getService().post<TicketBitrix>('/abrirTicket', abrirTicketParam), param);
}

export function consultarComentariosTicket(numeroTicket: string, param: AxiosParam<ComentarioTicketBitrix[]>) {
  send(getService().get<ComentarioTicketBitrix[]>(`/consultarComentariosTicket/${numeroTicket}`), param);
}

export function adicionarComentarioTicket(novoComentarioTicketParam: NovoComentarioTicketParam, param: AxiosParam<TicketBitrix>) {
  send(getService().post<TicketBitrix>('/adicionarComentarioTicket', novoComentarioTicketParam), param);
}

export function concluirTicket(numeroTicket: string, param: AxiosParam<TicketBitrix>) {
  send(getService().post<TicketBitrix>(`/concluirTicket/${numeroTicket}`), param);
}

export function avaliarTicket(avaliacaoTicketBitrix: AvaliacaoTicketBitrix, param: AxiosParam<TicketBitrix>) {
  send(getService().post<TicketBitrix>('/avaliarTicket', avaliacaoTicketBitrix), param);
}

export function notificaTickets(usuario: Usuario, param: AxiosParam<void>) {
  send(getService().post<void>('/notificaTickets', usuario, getHeaders()), param);
}

export function verificarTicketsPendenteCliente(param: AxiosParam<boolean>) {
  send(getService().get<boolean>('/verificarTicketsPendenteCliente'), param);
}

export function getStatsGerenteEmail(windowId: string, param: AxiosParam<any>) {
  send(getService().get<any>(`/getStatsGerenteEmail/${windowId}`), param);
}

export function cancelEmail(emailId: string, param: AxiosParam<void>) {
  send(getService().post(`/cancelEmail/${emailId}`), param);
}

export function downloadAnexoEmail(idAnexo: string, param: AxiosParam<any>) {
  send(getService().get(`/downloadAnexoEmail/${idAnexo}`), param);
}
